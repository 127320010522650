<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">

            <!-- Brand logo-->
            <b-link class="brand-logo">
                <b-img
                    fluid
                    :src="logoImg"
                    width="120"
                />
            </b-link>
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col
                lg="7"
                class="d-none d-lg-flex align-items-center p-5"
            >
                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                    <b-img
                        :src="imgUrl"
                        fluid
                        alt="Register V2"
                    />
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Reset password-->
            <b-col
                lg="5"
                class="d-flex align-items-center auth-bg px-2 p-lg-5"
            >
                <div class="sk-picker">
                    <b-form-select
                        v-model="locale"
                        :options="cultures"
                        label="text"
                    />
                </div>
                <b-col
                    sm="8"
                    md="6"
                    lg="12"
                    class="px-xl-2 mx-auto"
                >
                    <b-card-title
                        title-tag="h2"
                        class="font-weight-bold mb-1"
                    >
                        {{i18nT(`Set password`)}} 🔒
                    </b-card-title>
                    <b-card-text class="mb-2" v-if="$route.query.onboard">
                        {{i18nT(`To access your profile, please set your desired password.`)}}
                    </b-card-text>
                    <b-card-text class="mb-2" v-else>
                        {{i18nT(`Your new password must be different from previously used passwords`)}}
                    </b-card-text>

                    <!-- form -->
                    <validation-observer ref="simpleRules">
                        <b-form
                            class="auth-reset-password-form mt-2"
                            method="POST"
                            @submit.prevent="validationForm"
                        >

                            <!-- password -->
                            <b-form-group
                                :label="i18nT(`Enter your Password`)"
                                label-for="reset-password-new"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    :name="i18nT(`Password`)"
                                    vid="Password"
                                    rules="required|password"
                                >
                                    <b-input-group
                                        class="input-group-merge"
                                        :class="errors.length > 0 ? 'is-invalid':null"
                                    >
                                        <b-form-input
                                            id="reset-password-new"
                                            v-model="password"
                                            :type="password1FieldType"
                                            :state="errors.length > 0 ? false:null"
                                            class="form-control-merge"
                                            name="reset-password-new"
                                            :placeholder="i18nT(`············`)"
                                        />
                                        <b-input-group-append is-text>
                                            <feather-icon
                                                class="cursor-pointer"
                                                :icon="password1ToggleIcon"
                                                @click="togglePassword1Visibility"
                                            />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- confirm password -->
                            <b-form-group
                                label-for="reset-password-confirm"
                                :label="i18nT(`Confirm password`)"
                                class="mt-1"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    :name="i18nT(`Confirm password`)"
                                    rules="required|confirmed:Password"
                                >
                                    <b-input-group
                                        class="input-group-merge"
                                        :class="errors.length > 0 ? 'is-invalid':null"
                                    >
                                        <b-form-input
                                            id="reset-password-confirm"
                                            v-model="cPassword"
                                            :type="password2FieldType"
                                            class="form-control-merge"
                                            :state="errors.length > 0 ? false:null"
                                            name="reset-password-confirm"
                                            :placeholder="i18nT(`············`)"
                                        />
                                        <b-input-group-append is-text>
                                            <feather-icon
                                                class="cursor-pointer"
                                                :icon="password2ToggleIcon"
                                                @click="togglePassword2Visibility"
                                            />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                             <!-- PASSWORD RULES -->

                             <div class="password-rules font-small-3 mb-2">
                                <div class="pb-1">{{i18nT(`To ensure that your account and information is well protected, please chose a password that has: `)}}</div>
                                <div class="d-flex">
                                    <div class="rule-column pr-2">
                                        <div :class="{ 'rule-met': isLengthValid }"><font-awesome-icon :icon="isLengthValid ? `check` : `times`" class="mr-1" :class="isLengthValid ? `text-success`: `text-danger`"></font-awesome-icon>{{i18nT(`Minimum 8 characters`)}}</div>
                                        <div :class="{ 'rule-met': hasUppercase }"><font-awesome-icon :icon="hasUppercase ? `check` : `times`" class="mr-1" :class="hasUppercase ? `text-success`: `text-danger`"></font-awesome-icon>{{i18nT(`At least one uppercase letter`)}}</div>
                                    </div>
                                    <div class="rule-column">
                                        <div :class="{ 'rule-met': hasNumber }"><font-awesome-icon :icon="hasNumber ? `check` : `times`" class="mr-1" :class="hasNumber ? `text-success`: `text-danger`"></font-awesome-icon>{{i18nT(`At least one number`)}}</div>
                                        <div :class="{ 'rule-met': hasSpecialCharacter }"><font-awesome-icon :icon="hasSpecialCharacter ? `check` : `times`" class="mr-1" :class="hasSpecialCharacter ? `text-success`: `text-danger`"></font-awesome-icon>{{i18nT(`At least one special character`)}}</div>
                                    </div>
                                </div>
                                <!-- Add other rules as needed -->
                            </div>

                            <!-- submit button -->
                            <b-button
                                block
                                :disabled="!canSubmit"
                                type="submit"
                                variant="primary"
                                v-if="$route.query.onboard"
                            >
                                {{i18nT(`Set Password`)}}
                            </b-button>
                            <b-button
                                :disabled="!canSubmit"
                                block
                                type="submit"
                                variant="primary"
                                v-else
                            >
                                {{i18nT(`Set new password`)}}
                            </b-button>
                        </b-form>
                    </validation-observer>

                    <p class="text-center mt-2" v-if="!$route.query.onboard">
                        <b-link :to="{name:'auth-login'}">
                            <feather-icon icon="ChevronLeftIcon" /> {{i18nT(`Back to login`)}}
                        </b-link>
                    </p>
                </b-col>
            </b-col>
            <!-- /Reset password-->
        </b-row>
    </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BLink,
    BFormInput,
    BButton,
    BImg, BFormSelect
} from 'bootstrap-vue'
import { required } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import useAuth from '@/auth/useAuth'
import {dictToSelectArray} from "@core/utils/utils";

export default {
    components: {
        BFormSelect,
        VuexyLogo,
        BRow,
        BCol,
        BButton,
        BCardTitle,
        BCardText,
        BForm,
        BFormGroup,
        BImg,
        BInputGroup,
        BLink,
        BFormInput,
        BInputGroupAppend,
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            userEmail: '',
            cPassword: '',
            password: '',
            sideImg: require('@/assets/images/pages/register.svg'),
            logoImg: require('@/assets/images/logo/logo_color.svg'),
            // validation
            required,
            locale: 'en',
            cultures: [],

            // Toggle Password
            password1FieldType: 'password',
            password2FieldType: 'password'
        }
    },
    computed: {
        canSubmit() {
            if(this.isLengthValid && this.hasUppercase && this.hasSpecialCharacter && this.hasNumber && this.cPassword === this.password) {
                return true
            } else {
                return false
            }
        },
        isLengthValid() {
            return this.password.length >= 8;
        },
        hasUppercase() {
            return /[A-Z]/.test(this.password);
        },
        hasSpecialCharacter() {
            return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(this.password);
        },
        hasNumber() {
            return /\d/.test(this.password);
        },
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        imgUrl() {
            if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.sideImg = require('@/assets/images/pages/register.svg')
                return this.sideImg
            }
            return this.sideImg
        },
        password1ToggleIcon() {
            return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        password2ToggleIcon() {
            return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        }
    },
    methods: {
        getCultures() {
            this.$http.get(`system/cultures`).then(({data}) => {
                //console.log("Cultures:", data.data)
                this.cultures = dictToSelectArray(data.data)
                if(this.$route.query.lang) {
                    this.locale = this.$route.query.lang;
                }
            })
        },
        togglePassword1Visibility() {
            this.password1FieldType =
                this.password1FieldType === 'password' ? 'text' : 'password'
        },
        togglePassword2Visibility() {
            this.password2FieldType =
                this.password2FieldType === 'password' ? 'text' : 'password'
        },
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    const formData = new FormData()

                    formData.append('new_password', this.password)
                    // formData.append('repeat_password', this.cPassword)
                    formData.append('hash', this.$route.query.hash)

                    this.$http
                        .post(`users/publicSetPassword`, formData)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })

                            router.push({ name: 'auth-login' })
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'InfoIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        }
    },
    created() {
        //console.log("",this.$route.query.lang);
        this.getCultures();
    },
    mounted() {

        localStorage.removeItem(useAuth.authConfig.storageTokenKeyName)
        localStorage.removeItem(useAuth.authConfig.storageUserTypeKeyName)
        localStorage.removeItem(useAuth.authConfig.storageRefreshTokenKeyName)
        localStorage.removeItem(useAuth.authConfig.storageCurWorkspaceKeyName)
        localStorage.removeItem(useAuth.authConfig.storageWorkspacesKeyName)
        localStorage.removeItem(useAuth.authConfig.storageProfileKeyName)
        localStorage.removeItem(useAuth.authConfig.storageSoftwaresKeyName)
        localStorage.removeItem(useAuth.authConfig.storageCurrentSoftwareKeyName)

        // Remove userData from localStorage
        localStorage.removeItem('userData')
    },
    watch: {
        locale(newVal, oldVal) {
            if (newVal === oldVal) return;
            this.changeLang(newVal);

            // Step 1: Clone the existing query parameters
            let updatedQuery = { ...this.$route.query };

            // Step 2: Update the 'lang' parameter
            updatedQuery.lang = newVal;

            // Step 3: Construct the new query string
            let queryString = Object.keys(updatedQuery)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(updatedQuery[key])}`)
                .join('&');

            // Update the URL without reloading the page
            history.pushState({}, null, `${this.$route.path}?${queryString}`);
        },
    }

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.brand-logo {
    svg {
        width: 120px;
    }
}
.sk-picker {
    width: 150px;
    position: absolute;
    top: 20px;
    right: 20px;
}

</style>
